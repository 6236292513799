<template>
  <div class="flex bg-white justify-between lg:w-4/5 w-6/7 lg:ml-auto mx-auto items-center rounded-[35px] md:py-12 py-10 md:px-6 px-3 my-4 cursor-pointer">
    <div class="">
      <h2 class="md:text-2xl text-xl font-bold">{{ title }}</h2>
      <div :class="showDesc ? 'md:h-[70px] h-[140px]' : 'h-[0px]'" class=" transition-all duration-500 overflow-hidden">
        <p class="mt-4" >{{ description }} <a v-if="telegramLink" :href="telegramLink" class="text-blue-500 hover:underline font-semibold"> администратору в телеграмм</a> </p>
      </div>
    </div>
    <img 
        src="@/assets/arrow.svg" 
        alt="" 
        width="26"
        class="transform transition-transform duration-700 self-start"
        :class="{'rotate-180': showDesc}"
    >
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    telegramLink: {
    type: String,
    required: false,
  },
    description: {
      type: String,
      required: true,
    },
    showDesc: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>

<style scoped>
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.animate-fadeIn {
    animation: fadeIn 1s ease-in-out;
}
</style>
