<template>
    <div class="shadow-inner mt-[100px]">
    <div class="xl:container mx-auto lg:px-8 px-2 py-4 font-montserrat">
        <search class="absolute top-[120px] left-0 right-0"></search>
    <div class="flex mt-5">
        <div v-if="passTripCount > 0 || perfTripCount > 0" class="min-w-[480px] h-full px-5 shadows font-medium py-5 mr-2">
            <h4 class="text-[22px] px-6">Время выезда</h4>
            <div :key="trip.id" v-for="trip in tripsTime">
                <div class="flex justify-between py-4 hover:bg-[#E8E7E7] px-6 rounded-[18px]">
                    <div class="text-[18px]">{{ trip }}</div>
                    <div class="inline-flex items-center">
                        <label class="relative flex cursor-pointer items-center rounded-full" for="checkbox" data-ripple-dark="true">
                          <input type="checkbox" class="before:content[''] peer  h-[24px] w-[24px] cursor-pointer appearance-none rounded-md border-2 border-[#07CAFF] checked:border-[#07CAFF] checked:bg-[#07CAFF]" id="checkbox" />
                          <div class="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M8.9375 16.5L2.75 10.3125L3.72213 9.34033L8.9375 14.555L18.2779 5.21533L19.25 6.18746L8.9375 16.5Z" fill="white"/>
                            </svg>
                          </div>
                        </label>
                      </div>
                    </div>
                </div>
            </div>
            <div v-else class="min-w-[480px] h-full px-5 text-[24px] font-medium py-5 mr-2">
            <p>Нет доступных фильтров</p>
          </div>
            <div class="w-full px-5">
                <searchDetail @updateActiveTab="updateActiveTab"></searchDetail>
                <div  v-if="passTripCount > 0 || perfTripCount > 0" class="text-[#ADADAD] text-[17px] font-medium">
                    <div class="flex items-center mt-10">{{ $route.query.selectedCity }}
                        <svg class="mx-1" xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                          <path d="M13.7132 7.20468L9.91013 10.7648C9.81817 10.848 9.74482 10.9474 9.69436 11.0574C9.6439 11.1674 9.61734 11.2856 9.61623 11.4053C9.61512 11.525 9.63948 11.6437 9.68789 11.7544C9.7363 11.8652 9.80779 11.9658 9.89819 12.0505C9.98859 12.1351 10.0961 12.202 10.2144 12.2473C10.3327 12.2927 10.4595 12.3155 10.5874 12.3144C10.7152 12.3134 10.8415 12.2885 10.959 12.2413C11.0765 12.194 11.1827 12.1254 11.2715 12.0393L16.7181 6.9406C16.8986 6.77158 17 6.54237 17 6.30338C17 6.06438 16.8986 5.83517 16.7181 5.66615L11.2715 0.56746C11.0899 0.40328 10.8467 0.312433 10.5943 0.314486C10.3418 0.31654 10.1004 0.41133 9.92186 0.57844C9.74334 0.74555 9.64209 0.971609 9.63989 1.20793C9.6377 1.44425 9.73475 1.67192 9.91013 1.84191L13.7132 5.40207L0.962802 5.40207C0.707451 5.40207 0.462559 5.49703 0.281998 5.66606C0.101438 5.83509 -2.73201e-07 6.06434 -2.62752e-07 6.30338C-2.52304e-07 6.54242 0.101438 6.77167 0.281998 6.9407C0.462559 7.10973 0.707451 7.20468 0.962802 7.20468L13.7132 7.20468Z" fill="#ADADAD"/>
                        </svg>
                        {{ $route.query.selectedCityTo }}
                    </div>
                </div>
                <div v-else class="mt-[86px]">
                    <div class="border-8 border-[#D9D9D9] rounded-[20px] mx-[-100px] text-[17px]"></div>
                    <div>
                        <div class="text-[44px] font-medium mt-10">На сегодня пока нет поездок между этими городами</div>
                        <div class="flex items-center text-[#ADADAD] pt-7 pb-12 pl-12">{{ $route.query.selectedCity }}
                            <svg class="mx-1" xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                                <path d="M13.7132 7.20468L9.91013 10.7648C9.81817 10.848 9.74482 10.9474 9.69436 11.0574C9.6439 11.1674 9.61734 11.2856 9.61623 11.4053C9.61512 11.525 9.63948 11.6437 9.68789 11.7544C9.7363 11.8652 9.80779 11.9658 9.89819 12.0505C9.98859 12.1351 10.0961 12.202 10.2144 12.2473C10.3327 12.2927 10.4595 12.3155 10.5874 12.3144C10.7152 12.3134 10.8415 12.2885 10.959 12.2413C11.0765 12.194 11.1827 12.1254 11.2715 12.0393L16.7181 6.9406C16.8986 6.77158 17 6.54237 17 6.30338C17 6.06438 16.8986 5.83517 16.7181 5.66615L11.2715 0.56746C11.0899 0.40328 10.8467 0.312433 10.5943 0.314486C10.3418 0.31654 10.1004 0.41133 9.92186 0.57844C9.74334 0.74555 9.64209 0.971609 9.63989 1.20793C9.6377 1.44425 9.73475 1.67192 9.91013 1.84191L13.7132 5.40207L0.962802 5.40207C0.707451 5.40207 0.462559 5.49703 0.281998 5.66606C0.101438 5.83509 -2.73201e-07 6.06434 -2.62752e-07 6.30338C-2.52304e-07 6.54242 0.101438 6.77167 0.281998 6.9407C0.462559 7.10973 0.707451 7.20468 0.962802 7.20468L13.7132 7.20468Z" fill="#ADADAD"/>
                            </svg>
                            {{ $route.query.selectedCityTo }}
                        </div>
                    </div>
                    <div class="flex justify-center">
                        <a href="https://hamroh.com/app/passenger/publish">
                            <div class="text-center text-white bg-[#07CAFF] py-5 rounded-[30px] px-[103px] mb-14">Создать поездку</div>
                        </a>
                    </div>
                    <div class="border-8 border-[#D9D9D9] rounded-[20px] mx-[-100px]"></div>
                </div>
                <PerfTrip v-if="tab !== 'passengers'" @updateTripCount="perfTripCount = $event"></PerfTrip>
                <PassTrip v-if="tab !== 'drivers'" @updateTripCount="passTripCount = $event"></PassTrip>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import search from '@/components/search/search'
import PerfTrip from '@/pages/PerfTrip/PerfTrip.vue';
import PassTrip from '@/pages/PassTrip/index.vue'
import searchDetail from '@/components/search/searchDetail.vue';
export default {
    components:{
        search,
        PerfTrip,
        PassTrip,
        searchDetail
    },
    data() {
        return {
            tripsTime: ['До 06:00', '06:00-12:00', '12:00:18:00', 'После 18:00'],
            passTripCount: 0,
            perfTripCount: 0,
            tab: 'all',
            tripCount: null
        }
    },
    methods: {
        updateActiveTab(tab) {
            this.tab = tab
        }
    }
    
}
</script>
<style>
.shadows {
-webkit-box-shadow: 0px 0px 10px -5px rgba(66, 68, 90, 1);
-moz-box-shadow: 0px 0px 10px -5px rgba(66, 68, 90, 1);
box-shadow: 0px 0px 10px -5px rgba(66, 68, 90, 1);
}
</style>