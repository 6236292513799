<template>
  <section>
    <div class="xl:container mx-auto font-sans lg:px-8 px-2 relative z-10 lg:pb-64 pb-96">
      <div class="lg:p-[64px] md:my-[80px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
        <div class="mb-[20px]">
          <h2 class="lg:text-[46px] lg:leading-[54px] md:leading-[40px] leading-[36px] md:text-[40px] text-[38px] font-bold">
            ПЕЙДЖ <br>
            НОТ <br>
            ФАУНД
          </h2>
        </div>
        <div class="mb-[20px] leading-[24px]">
          <p class="text-[18px] font-normal">
            Ошибка 404. Такая страница не существует или находится на стадии разработки.
          </p>
        </div>
        <div class="mt-[12px]">
          <button @click="toHome()" class="border-none leading-[20px] font-medium rounded-[10px] text-[16px] hover:opacity-80 mt-[12px] duration-200 px-[25px] py-[14px] bg-[#07CAFF] text-white">
            На главную
          </button>
        </div>
      </div>
    </div>
    <div class="fixed bottom-[-50px] right-0">
    <img class="" src="../assets/404.png" alt="">
  </div>
  </section>
</template>

<script>
export default {
methods: {
  toHome() {
    this.$router.push({name: 'home'})
  }
}
}
</script>