<template>
    <div class="xl:container mx-auto font-sans lg:px-8 px-2 py-4 ">
      <h2  class="text-[2.5rem] font-semibold mb-5">{{ job.title }}</h2>
      <div class="lg:flex">
        <div class="bg-white rounded-md p-6 w-full mr-6">
          <div class="flex">
            <img src="@/assets/location.svg" alt="">
            <h4 class="text-[#949494] mx-1 lg:my-2 my-4">{{ job.location }}</h4>
          </div>
          <p class="text-[#07CAFF] my-4 text-[18px]">{{ job.shortDiscript }}</p>
          <p class="my-2 text-[18px]" v-html="job.description"></p>
          <div>
            <ul class="text-[18px]">
            <li v-for="responsibility in job.responsibilities" :key="responsibility.title">
              <h5 class="font-semibold text-[#07CAFF]">{{ responsibility.title }}</h5>
              <ul class=" list-disc">
                <li class="my-4 flex mx-0" v-for="item in responsibility.text" :key="item">
                  <img class="mx-4" src="@/assets/point.svg" alt="">
                  {{ item }}</li>
              </ul>
            </li>
            <li v-for="requirement in job.requirements" :key="requirement.title">
              <h5 class="font-semibold text-[#07CAFF]">{{ requirement.title }}</h5>
              <ul>
                <li class="my-4 flex mx-0" v-for="item in requirement.text" :key="item">
                  <img class="mx-4" src="@/assets/point.svg" alt="">
                  {{ item }}</li>
              </ul>
            </li>
            <li v-for="value in job.values" :key="value.title">
              <h5 class="font-semibold text-[#07CAFF]">{{ value.title }}</h5>
              <ul>
                <li class="my-4 flex mx-0" v-for="item in value.text" :key="item">
                  <img class="mx-4" src="@/assets/point.svg" alt="">
                  {{ item }}</li>
              </ul>
            </li>
            <li v-for="offer in job.offers" :key="offer.title">
              <h5 class="font-semibold text-[#07CAFF]">{{ offer.title }}</h5>
              <ul>
                <li class="my-4 flex mx-0" v-for="item in offer.text" :key="item">
                  <img class="mx-4" src="@/assets/point.svg" alt="">
                  {{ item }}</li>
              </ul>
            </li>
            <li v-for="expect in job.expectation" :key="expect.title">
              <h5 class="font-semibold text-[#07CAFF]">{{ expect.title }}</h5>
              <ul>
                <li class="my-4 flex mx-0" v-for="item in expect.text" :key="item">
                  <img class="mx-4" src="@/assets/point.svg" alt="">
                  {{ item }}</li>
              </ul>
            </li>
          </ul>
          <p class="text-[#07CAFF] text-[18px] my-8" v-html="job.contacts"></p>
          </div>

        </div>
        <div class="border rounded-md p-6 max-w-[500px] lg:max-h-[300px] w-full">
          <h2 class="text-[2rem] font-semibold mb-6">Заинтересовала вакансия?</h2>
          <p class="text-[20px]">Прикрепите своё резюме и пройдите небольшой тест, после чего мы сообщим вам о нашем решении.</p>
          <a href="https://t.me/Gram_tj" target="_blank">
            <button class="bg-[#07CAFF] text-white text-[16px] font-bold py-4 px-8 w-full rounded-md mt-6 hover:shadow-lg active:bg-sky-500">Откликнуться</button>
          </a>
        </div>
      </div>
  <h2 class="text-[2.0rem] font-semibold my-5">Похожие вакансии</h2>
    <ul class="grid lg:grid-cols-3 gap-4 mb-6" v-if="similarJobs.length > 0">
      <li class="bg-white rounded-md mx-0 p-6 w-full hover:text-sky-400 duration-300 hover:drop-shadow-xl" v-for="similarJob in similarJobs" :key="similarJob.id">
        <a :href="`/jobDetail/${similarJob.id}`">
          <div class="lg:flex justify-between">
          <h2 class="text-[1.50rem] font-bold">{{ similarJob.title }}</h2>
          <div class="flex">
              <img src="@/assets/location.svg" alt=""> 
              <h4 class="text-[#949494] mx-1 lg:my-2 my-4">{{ similarJob.location}}</h4>
          </div>
          </div>
        <p class="text-[18px] lg:pt-6 text-black">{{ similarJob.shortDiscript }}</p>
        </a>
      </li>
    </ul>
    <div v-else class="pt-6 px-6 text-[#222] mb-6">
    <h2 class="text-[28px] font-bold">Пусто</h2>
    <p class="text-[16px] py-4">К сожалению, у нас пока нет похожих вакансии.</p>
    <a href="/jobs">
    <h4 class="text-[#07CAFF] text-[18px] hover:underline underline-offset-8 cursor-pointer">Посмотреть другие вакансии</h4>
    </a>
  </div> 

    </div>
  </template>
  
  <script>
  import jobs from '@/data/jobs';
  
  export default {
    data() {
      return {
        
      };
    },
    created() {
      this.jobId = this.$route.params.id;
      this.job = this.getJobById(this.jobId);
    },
    methods: {
      getJobById(id) {
        return jobs.find(job => job.id === id) || {};
      },
    },
    computed: {
    job() {
      const jobId = Number(this.$route.params.id);
      return jobs.find(job => job.id === jobId) || {};
    },
    similarJobs() {
    if (this.job.filterId) {
      const similarJobs = jobs.filter(job => job.filterId === this.job.filterId && job.id !== this.job.id);
      const shuffledJobs = similarJobs.sort(() => Math.random() - 0.5);
      return shuffledJobs.slice(0, Math.min(3, shuffledJobs.length));
    }
    return [];
  }
  },
  };
  </script>
  
  
  
  
