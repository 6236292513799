<template>
    <div class="xl:container mx-auto font-sans lg:px-8 px-2 md:py-16 py-10">
        <h2 class="md:text-[56px] text-[32px] dark:text-gray-100">Найдите пассажиров где угодно</h2>
        <div class="grid lg:grid-cols-2 gap-6 justify-items-center">
            <div class="lg:ml-28">
                <img src="@/assets/whysafe-1.svg" class="dark:filter dark:invert dark:hue-rotate-180" alt="">
            </div>
            <div >
                <div class="md:mt-28 mb-8 dark:text-gray-100">
                    <h2 class="md:text-2xl text-[20px] font-bold">Экономьте, когда вы за рулем</h2>
                    <p class="md:text-xl text-[16px]">Хотите совершить междугородную поездку по делам, оформите поездку и экономьте расходы на топлива</p>
                </div>
                <div class="dark:text-gray-100">
                    <h2 class="md:text-2xl text-[20px] font-bold ">Находите пассажиров где угодно</h2>
                    <p class="md:text-xl text-[16px]">Не важно где вы находитесь вы можете найти пассажиров где бы вы не были оформив поездку с помощью бота</p>
                </div>
                <div class="mt-16">
                    <a href="https://hamroh.com/app/performer/trips">
                        <div class="hover:bg-sky-600  active:bg-sky-700 bg-[#07CAFF] text-[16 
                        
                        px] inline md:py-6 py-4 md:px-16 px-8 rounded-2xl cursor-pointer ">
                            Найти пассажиров
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>