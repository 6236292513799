<template>
    <div class="z-0">
      <div class="border-1 w-full rounded-xl bg-gray-300 pt-8 pb-10 mt-4 animate-pulse">
          <div class="flex justify-between mx-5 mb-2 items-center">
              <div class="flex items-center">
                  <div class="h-5 w-5 bg-white rounded-full mr-1.5"></div>
                  <div class="h-4 w-20 bg-white rounded"></div>
              </div>
              <div class="h-5 w-12 bg-white rounded"></div>
          </div>
          <div class="flex ml-5 items-center">
              <div class="h-5 w-5 bg-white rounded-full mr-1.5"></div>
              <div class="h-4 w-20 bg-white rounded"></div>
          </div>
      </div>
    </div>
</template>