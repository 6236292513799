<template>
  <div class="flex justify-between bg-white px-[120px] shadows rounded-[25px] pt-3 text-[14px]">
    <div class="cursor-pointer px-4" @click="setActiveTab('all')">Все
      <div class="flex justify-center pt-[14px] mb-[16px]">{{ allCount }}</div>
      <div :class="{ 'border-2 border-[#000] rounded-[20px] lg:mx-[-100px]': activeTab === 'all' }"></div>
    </div>
    <div class="cursor-pointer px-2" @click="setActiveTab('drivers')">Водители
      <div class="flex justify-center items-center pt-2 mb-3">
        <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="28" height="31" viewBox="0 0 28 31" fill="none">
          <path
            d="M4.89991 23.3442C4.89991 23.3442 4.78674 24.3194 4.89991 24.7832C4.97866 25.1048 5.05391 25.6466 5.35491 25.6466H7.04482C7.34582 25.6466 7.42107 25.1048 7.49982 24.7832C7.61299 24.3201 7.49982 23.3442 7.49982 23.3442"
            stroke="#343434" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M22.7751 13.9888H5.22492C3.78902 13.9888 2.625 15.2775 2.625 16.8672V20.4658C2.625 22.0556 3.78902 23.3443 5.22492 23.3443H22.7751C24.211 23.3443 25.375 22.0556 25.375 20.4658V16.8672C25.375 15.2775 24.211 13.9888 22.7751 13.9888Z"
            stroke="#343434" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M20.5005 23.3442C20.5005 23.3442 20.3873 24.3194 20.5005 24.7832C20.5792 25.1048 20.6545 25.6466 20.9555 25.6466H22.6454C22.9464 25.6466 23.0217 25.1048 23.1004 24.7832C23.2136 24.3201 23.1004 23.3442 23.1004 23.3442"
            stroke="#343434" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M6.20064 20.4658C6.91875 20.4658 7.50089 19.8213 7.50089 19.0262C7.50089 18.2312 6.91875 17.5867 6.20064 17.5867C5.48253 17.5867 4.90039 18.2312 4.90039 19.0262C4.90039 19.8213 5.48253 20.4658 6.20064 20.4658Z"
            stroke="#343434" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M5.22461 13.9887L6.71969 7.51168C6.97519 6.40537 8.09228 5.35266 9.12478 5.35266H18.8746C19.9071 5.35266 21.0242 6.40537 21.2797 7.51168L22.7748 13.9887"
            stroke="#343434" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M21.7993 20.4658C22.5174 20.4658 23.0995 19.8213 23.0995 19.0262C23.0995 18.2312 22.5174 17.5867 21.7993 17.5867C21.0812 17.5867 20.499 18.2312 20.499 19.0262C20.499 19.8213 21.0812 20.4658 21.7993 20.4658Z"
            stroke="#343434" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        {{ driversCount }}
      </div>
      <div :class="{ 'border-2 border-[#000] rounded-[20px] mx-[-100px]': activeTab === 'drivers' }"></div>
    </div>
    <div class="cursor-pointer" @click="setActiveTab('passengers')">Пассажиры
      <div class="flex justify-center items-center pt-2 mb-3">
        <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="28" height="31" viewBox="0 0 28 31" fill="none">
          <path
            d="M4.66699 23.2499C4.66699 21.8796 5.15866 20.5655 6.03383 19.5965C6.909 18.6276 8.09598 18.0833 9.33366 18.0833H18.667C19.9047 18.0833 21.0917 18.6276 21.9668 19.5965C22.842 20.5655 23.3337 21.8796 23.3337 23.2499C23.3337 23.9351 23.0878 24.5921 22.6502 25.0766C22.2127 25.5611 21.6192 25.8333 21.0003 25.8333H7.00033C6.38149 25.8333 5.78799 25.5611 5.35041 25.0766C4.91282 24.5921 4.66699 23.9351 4.66699 23.2499Z"
            stroke="#343434" stroke-linejoin="round" />
          <path
            d="M14.001 14.9166C15.934 14.9166 17.501 13.1817 17.501 11.0416C17.501 8.90152 15.934 7.16663 14.001 7.16663C12.068 7.16663 10.501 8.90152 10.501 11.0416C10.501 13.1817 12.068 14.9166 14.001 14.9166Z"
            stroke="#343434" />
        </svg>
        {{ passengersCount }}
      </div>
      <div :class="{ 'border-2 border-[#000] rounded-[20px] mx-[-100px]': activeTab === 'passengers' }"></div>
    </div>
  </div>
</template>
<script>
import api from '@/api/axios'
export default {
  data() {
    return {
      trips: [],
      params: {
        from_city_id: this.$route.query.selectCityId,
        to_city_id: this.$route.query.selectedCityToId,
        // seat_count: this.$route.query.selectedSeats,
        start_date: this.$route.query.startdate
      },
      activeTab: 'all',
      allCount: 0,
      driversCount: 0,
      passengersCount: 0,
    };
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
      this.$emit('updateActiveTab', tab);
    },
    getPassTrip() {
      api.get('v1/get/passenger-trip', { params: this.params })
        .then(res => {
          this.trips = res.data.result.data
          this.passengersCount = this.trips.length
          this.allCount += this.trips.length;
        })
        .catch(error => {
          console.error(error);
        });
    },
    getPerfTrip() {
      api.get('v1/get/performer-trip', { params: this.params })
        .then(res => {
          this.trips = res.data.result.data
          this.driversCount = this.trips.length
          this.allCount += this.trips.length;
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
  mounted() {
    this.getPassTrip()
    this.getPerfTrip()
  },
};
</script>

<style>
.shadows {
  -webkit-box-shadow: 0px 0px 15px -5px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 15px -5px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 15px -5px rgba(66, 68, 90, 1);
}
</style>