<template>
    <div class="xl:container mx-auto font-sans lg:px-8 px-2 md:py-12 py-5">
      <div>
        <h1 class="sm:text-[56px] text-[32px] md:mb-16 mb-10 dark:text-gray-100">Часто задаваемые вопросы</h1>
        <div>
          <CardFAQ
            title="Как найти водителей/пассажиров?"
            description="Чтобы найти водителей/пассажиров, возвращайтесь в начала страницы и нажмите раздел Поездки водителей/Поездки пассажиров."
            :showDesc="activeQuestion === 1"
            @click="toggleQuestion(1)"
          />
          <CardFAQ
            title="Как получить информацию о предстоящих поездках ?"
            description="Нажмите кнопку Найти водителя/пассажиров и нажимая на интересующую заявку узнайте всю информацию о поездке."
            :showDesc="activeQuestion === 2"
            @click="toggleQuestion(2)"
          />
          <CardFAQ
            title="Как сделать предварительное бронирование ?"
            description="Чтобы сделать предварительное бронирование заранее найдите нужное объявление, позвоните по указанному номеру и договоритесь с водителем/пассажиром."
            :showDesc="activeQuestion === 3"
            @click="toggleQuestion(3)"
          />
          <CardLink
            title="Что делать если я не нашел нужный город ?"
            description="Если не нашли нужный город, напишите" 
            telegramLink="https://t.me/Gram_tj"
            :showDesc="activeQuestion === 4"
            @click="toggleQuestion(4)"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import CardFAQ from '../cards/CardFAQ.vue';
  import CardLink from '../cards/CardLink.vue';
  
  export default {
    components: {
      CardFAQ,
      CardLink
    },
    data() {
      return {
        activeQuestion: null,
      };
    },
    methods: {
      toggleQuestion(index) {
        if (this.activeQuestion === index) {
          this.activeQuestion = null;
        } else {
          this.activeQuestion = index;
        }
      },
    },
  };
  </script>
  