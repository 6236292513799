<template>
    <div>
      <div class="border-1 bg-white py-3.5 mt-11 shadow-md w-[410px] mx-auto">
        <div class="flex items-center justify-between px-5">
          <p class="text-lg font-bold">Информация о поездке</p>
          <p class="text-gray-600">Статус: {{ status }}</p>
        </div>
        <div class="flex mt-3.5 ml-5">
            <svg class="mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 8H4v8h16v-8zm-5-6H9v2H7V5H4v4h16V5h-3v2h-2V5zm-9 8h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z" fill="rgba(14,71,104,1)"/></svg>
          <p class="text-gray-600">{{ startDate }}</p>
        </div>
        <div class="flex mt-2 ml-5">
            <svg class="mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v14h16V5H4zm4.5 9H14a.5.5 0 1 0 0-1h-4a2.5 2.5 0 1 1 0-5h1V6h2v2h2.5v2H10a.5.5 0 1 0 0 1h4a2.5 2.5 0 1 1 0 5h-1v2h-2v-2H8.5v-2z" fill="rgba(14,71,104,1)"/></svg>
          <p class="text-gray-600">{{ price }} смн за 1 пассажира</p>
        </div>
        <div class="flex mt-2 ml-5">
            <svg class="mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z" fill="rgba(14,71,104,1)"/></svg>

          <p class="text-gray-600">{{ seatCount }} места</p>
        </div>
        <div class="flex mt-2 ml-5">
            <svg class="mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M14.45 19L12 22.5 9.55 19H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-6.55zm-1.041-2H20V5H4v12h6.591L12 19.012 13.409 17z" fill="rgba(14,71,104,1)"/></svg>

          <p class="text-gray-600 w-72">{{ comment }}</p>
        </div>
      </div>
      <div class="border-1 bg-white py-3.5 mt-4 shadow-md w-[410px] mx-auto">
        <p class="text-lg font-bold ml-5">Маршрут</p>
        <div class="flex mt-2 ml-5 items-center">
            <svg class="mr-1.5" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.5" cy="7.5" r="6" fill="white" stroke="#EB5D35" stroke-width="3"/>
            </svg>
          <p class="text-gray-600">{{ from_city }}</p>
        </div>
        <div class="flex ml-5 items-center">
            <svg class="mr-1.5" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.5" cy="7.5" r="6" fill="white" stroke="black" stroke-width="3"/>
            </svg>
          <p class="text-gray-600">{{ to_city }}</p>
        </div>
      </div>
      <div v-if="marka" class="border-1 bg-white py-3.5 mt-4 shadow-md w-[410px] mx-auto">
        <p class="text-lg font-bold ml-5">Информация об авто</p>
        <div class="flex mt-2 ml-5 items-center">
            <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  width="22" height="22"><path d="M19 20H5V21C5 21.5523 4.55228 22 4 22H3C2.44772 22 2 21.5523 2 21V11L4.4805 5.21216C4.79566 4.47679 5.51874 4 6.31879 4H17.6812C18.4813 4 19.2043 4.47679 19.5195 5.21216L22 11V21C22 21.5523 21.5523 22 21 22H20C19.4477 22 19 21.5523 19 21V20ZM20 13H4V18H20V13ZM4.17594 11H19.8241L17.6812 6H6.31879L4.17594 11ZM6.5 17C5.67157 17 5 16.3284 5 15.5C5 14.6716 5.67157 14 6.5 14C7.32843 14 8 14.6716 8 15.5C8 16.3284 7.32843 17 6.5 17ZM17.5 17C16.6716 17 16 16.3284 16 15.5C16 14.6716 16.6716 14 17.5 14C18.3284 14 19 14.6716 19 15.5C19 16.3284 18.3284 17 17.5 17Z"></path></svg>
          <p class="text-gray-600">{{ marka }}</p>
        </div>
        <div class="flex ml-5 items-center">
            <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  width="22" height="22"><path d="M19 20H5V21C5 21.5523 4.55228 22 4 22H3C2.44772 22 2 21.5523 2 21V11L4.4805 5.21216C4.79566 4.47679 5.51874 4 6.31879 4H17.6812C18.4813 4 19.2043 4.47679 19.5195 5.21216L22 11V21C22 21.5523 21.5523 22 21 22H20C19.4477 22 19 21.5523 19 21V20ZM20 13H4V18H20V13ZM4.17594 11H19.8241L17.6812 6H6.31879L4.17594 11ZM6.5 17C5.67157 17 5 16.3284 5 15.5C5 14.6716 5.67157 14 6.5 14C7.32843 14 8 14.6716 8 15.5C8 16.3284 7.32843 17 6.5 17ZM17.5 17C16.6716 17 16 16.3284 16 15.5C16 14.6716 16.6716 14 17.5 14C18.3284 14 19 14.6716 19 15.5C19 16.3284 18.3284 17 17.5 17Z"></path></svg>
          <p class="text-gray-600">{{ model }}</p>
        </div>
      </div>
      <!-- <div class="border-1 bg-white py-3.5 mt-4 shadow-md w-[410px] mx-auto">
        <p class="ml-5 text-center font-bold mt-4">Чтобы бронировать поездку пройдите авторизацию</p>
      </div> -->
    </div>
  </template>
  
  
  <script> 
  export default {
    props: {
      status: {
        type: String,
        required: true,
      },
      startDate: {
        type: String,
        required: true,
      },
      price: {
        type: String,
        required: true,
      },
      seatCount: {
        type: String,
        required: true,
      },
      comment: {
        type: String,
        required: true,
      },
      from_city: {
        type: String,
        required: true,
      },
      to_city: {
        type: String,
        required: true,
      },
      marka: {
        type: String,
        required: false,
      },
      model: {
        type: String,
        required: false,
      },
    },
  };
  </script>
  