<template>
    <div class="block">
        <div :class="{'buttons': true, 'md:border-l-0': selectedOption === 'option1', 'md:border-r-0': selectedOption === 'option2' }">
            <div class="btn" :class="{ selected: selectedOption === 'option1' }" @click="selectOption('option1')">Ищу водителей</div>
            <div class="btn" :class="{ selected: selectedOption === 'option2' }" @click="selectOption('option2')">Ищу пассажиров</div>
        </div>
      <PerfTrip class="content" v-if="selectedOption === 'option1'">
        <div class="text">
            Показать ещё 
        </div>
      </PerfTrip>
      <index class="content" v-if="selectedOption === 'option2'">
        <div class="text">
            Показать ещё 
        </div>
      </index> 
    </div>
</template>

<script>
import index from '@/pages/PassTrip/index'
import PerfTrip from '@/pages/PerfTrip/PerfTrip'
export default {
    components:{
        index,
        PerfTrip,
    },
    data() {
    return {
      selectedOption: 'option1', 
    };
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
    },
  },
};
</script>

<style>
.block{
@apply w-[358px] sm:w-[500px] md:w-auto max-w-[700px] mt-[32px] md:mt-[48px] justify-center items-center mx-auto
}
.buttons{
@apply w-[330px] sm:w-auto max-w-[700px] h-[46px] mx-auto md:h-[80px] md:text-[25px]  flex justify-center items-center border border-[#000] border-opacity-30 md:border-opacity-100 rounded-[50px];
border: 1px solid #000;
}
.buttons.border-r-0 {
  border-left: none;
  border-right: 1px solid black;
}
.buttons.border-l-0 {
  border-right: none;
  border-left: 1px solid black;
}
.btn{
@apply   h-full w-[360px] pt-[9px]  md:py-[18px]  text-center
}
.selected{
@apply bg-[#07CAFF] md:h-[80px] text-white border rounded-[50px]
}
.text{
@apply md:text-[25px] text-[#07CAFF] font-medium flex justify-end mx-auto  mt-[30px]
}
</style>