<template>
    <div>
        1
    </div>
    <!-- <div class="flex justify-center items-center h-screen">
        <div class="flex justify-center items-center">
            <div class="mx-2">
                <div @click="increaseCounter('qr-scan')">
                    <button class="container_border">
                        <a href="https://play.google.com/store/apps/details?id=com.gram.hamroh" target="_blank">
                            <img src="../../assets/svg/hamroh.svg" alt="hamroh">
                        </a>
                    </button>
                </div>
                <div class="mt-[18.23px]" @click="increaseCounter('qr-arzon-scan')">
                    <button class="container_border">
                        <a href="https://arzon666.tj/#/mobile-app" target="_blank">
                           <img src="../../assets/svg/arzon.svg" alt="arzon">
                        </a>
                    </button>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
import api from '@/api/axios';
export default {
    data() {
        return {
            
        }
    },
    mounted() {
        this.qrscan()
        this.increaseCounter()
    },  
    methods: {
        increaseCounter(){
            api.post(`v1/get/qr-scan?api_key=1545`)
                .then(res=> {
                if (res.data.code==200) {
                    window.location.href = 'https://play.google.com/store/apps/details?id=com.gram.hamroh';
                }
                console.log(res);
            })
        },
        qrscan() {
            api.post('v1/get/main-qr-scan?api_key=1545')
                .then((res) => {
                    console.log(res.message);
                })
        },
    }
}
</script>

<!-- <script>
import api from '@/api/axios';
    export default {
        data(){
            return {

            }
        },
        mounted() {
            this.qrscan()
        },  
        methods: {
            qrscan() {
                api.post('v1/get/main-qr-scan?api_key=1545')
                    .then((res) => {
                        console.log(res.message);
                    })
            },
            increaseCounter(name){
                api.post(`v1/get/${name}?api_key=1545`)
                    .then(res=> {
                        console.log(res);
                    })
            },
        }
    }
</script>

<style scoped>
.container_border {
    @apply border border-black bg-white rounded-8 py-[14.16px] px-[40.22px];
}
</style> -->