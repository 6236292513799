<template>
    <div class="flex bg-white justify-between lg:ml-auto mx-auto items-center rounded-md py-[24px] px-6 my-4 cursor-pointer hover:drop-shadow-md">
      <div class="text-[18px]">
        <h2 class="font-bold">{{ title }}</h2>
        <p :class="`overflow-hidden duration-300 p-0 ${showDesc ? 'h-[60px] mt-2' : 'h-[0px]'}`" >{{ description }}</p>
      </div>
    <img src="@/assets/arrow.svg" alt="" :class="{'transform rotate-180 duration-200': showDesc }" class=" transform transition-transform duration-300 self-start">
    </div>
  </template>

  <script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      showDesc: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
  };
  </script>